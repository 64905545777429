// import axios from 'axios';
// // import React from 'react';
// import React, { Component } from 'react';
// import {Dropdown, DropdownItem} from "reactstrap";
// import { BrowserRouter, Routes, Route, Navigation } from 'react-router-dom'
// import TeamDetailPage from './pages/TeamDetailPage'
// import Home from './pages/Home'
//
//
//
// class App extends Component {
//     // constructor(props) {
//     //     super(props);
//     //     this.state = {
//     //         team: "DAL",
//     //         teamList: [],
//     //         playerList: [],
//     //     };
//     // }
//
//     // componentDidMount() {
//     //     // this.refreshPlayers();
//     //     this.refreshTeams();
//     // };
//
//     // refreshPlayers = () => {
//     //     axios
//     //         .get("http://localhost:8000/api/players/")
//     //         .then(res => this.state({ playerList: res.data }))
//     //         .catch(err => console.log(err))
//     // };
//
//     // refreshTeams = () => {
//     // axios
//     //   .get("http://localhost:8000/api/teams/")
//     //   .then(res => this.setState({ teamList: res.data }))
//     //   .catch(err => console.log(err));
//     // };
//
//     // displayPlayersFromTeamSelected = abb => {
//     //     if (status) {
//     //         return this.setState({ teamSelected: {true}})
//     //     }
//     //     return this.setState({ teamSelected: false})
//     // }
//
//     // renderTeamList = () => {
//     //     const teams = this.state.teamList
//     //     return teams.map(team => (
//     //         // <option key={team.id} >{team.location + ' ' + team.name}</option>
//     //         <li className="list-group-item list-group-item-action list-group-item-dark" key={team.id} >
//     //             <span className="me-10">
//     //                 <img className="photo align-items-center"
//     //                      src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
//     //                      alt={"{team.abb} logo"}/>
//     //             </span>
//     //             <span>
//     //                 {'    ' + team.location + ' ' + team.name}
//     //             </span>
//     //         </li>
//     //         // ./team_logos/${team.abb}.png
//     //     ));
//         // return (
//         //     <div className="my-5 tab-list">
//         //         <select className="form-select">
//         //             {teams.map() => {
//         //                 return <option>{team.name}</option>;
//         //
//         //         </select>
//         //     </div>
//         // )
//     }
//     // renderPlayerList = (teamList) => {
//     //     return (
//     //         <div className="my-5 tab-list">
//     //             <select className="select2-dropdown--below">
//     //                 {teamList ? teamList.map((team) => {
//     //                     return <option>{team.name}</option>;
//     //                 })
//     //                 : null}
//     //             </select>
//     //         </div>
//     //     )
//     // }
//
//     // renderPlayers = () => {
//     //     const { teamSelected } = this.state;
//     //     const newTeams = this.state.playerList.filter(
//     //         player => player.current_team === teamSelected
//     //     );
//     //     return newPlayers.map(player => (
//     //         <li
//     //             key={player.id}
//     //             className="list-group-item d-flex justify-content-between align-items-center"
//     //         >
//     //             <span className={`todo-title me-2`}
//     //                   title={player.first_name + ' ' + player.last_name}>
//     //                   <img src={team.logo} alt={team.name + ' logo'} ></img>
//     //                   {team.location + ' '}
//     //                   {team.name}
//     //             </span>
//     //             <span>
//     //                 <Button className="btn btn-dark me-2">Player Page (coming soon)</Button>
//     //             </span>
//     //         </li>
//     //     ))
//     //
//     // };
//
//     render() {
//         return(
//             // <main className="context">
//                 <BrowserRouter>
//                     <Routes>
//                         <Route
//                             path="/"
//                             element=<Home />
//                         />
//                     </Routes>
//                 </BrowserRouter>import axios from 'axios';
// // import React from 'react';
// import React, { Component } from 'react';
// import {Dropdown, DropdownItem} from "reactstrap";
// import { BrowserRouter, Routes, Route, Navigation } from 'react-router-dom'
// import TeamDetailPage from './pages/TeamDetailPage'
// import Home from './pages/Home'
//
//
//
// class App extends Component {
//     // constructor(props) {
//     //     super(props);
//     //     this.state = {
//     //         team: "DAL",
//     //         teamList: [],
//     //         playerList: [],
//     //     };
//     // }
//
//     // componentDidMount() {
//     //     // this.refreshPlayers();
//     //     this.refreshTeams();
//     // };
//
//     // refreshPlayers = () => {
//     //     axios
//     //         .get("http://localhost:8000/api/players/")
//     //         .then(res => this.state({ playerList: res.data }))
//     //         .catch(err => console.log(err))
//     // };
//
//     // refreshTeams = () => {
//     // axios
//     //   .get("http://localhost:8000/api/teams/")
//     //   .then(res => this.setState({ teamList: res.data }))
//     //   .catch(err => console.log(err));
//     // };
//
//     // displayPlayersFromTeamSelected = abb => {
//     //     if (status) {
//     //         return this.setState({ teamSelected: {true}})
//     //     }
//     //     return this.setState({ teamSelected: false})
//     // }
//
//     // renderTeamList = () => {
//     //     const teams = this.state.teamList
//     //     return teams.map(team => (
//     //         // <option key={team.id} >{team.location + ' ' + team.name}</option>
//     //         <li className="list-group-item list-group-item-action list-group-item-dark" key={team.id} >
//     //             <span className="me-10">
//     //                 <img className="photo align-items-center"
//     //                      src={`https://assets.nhle.com/logos/nhl/svg/${team.abb}_light.svg`}
//     //                      alt={"{team.abb} logo"}/>
//     //             </span>
//     //             <span>
//     //                 {'    ' + team.location + ' ' + team.name}
//     //             </span>
//     //         </li>
//     //         // ./team_logos/${team.abb}.png
//     //     ));
//         // return (
//         //     <div className="my-5 tab-list">
//         //         <select className="form-select">
//         //             {teams.map() => {
//         //                 return <option>{team.name}</option>;
//         //
//         //         </select>
//         //     </div>
//         // )
//     }
//     // renderPlayerList = (teamList) => {
//     //     return (
//     //         <div className="my-5 tab-list">
//     //             <select className="select2-dropdown--below">
//     //                 {teamList ? teamList.map((team) => {
//     //                     return <option>{team.name}</option>;
//     //                 })
//     //                 : null}
//     //             </select>
//     //         </div>
//     //     )
//     // }
//
//     // renderPlayers = () => {
//     //     const { teamSelected } = this.state;
//     //     const newTeams = this.state.playerList.filter(
//     //         player => player.current_team === teamSelected
//     //     );
//     //     return newPlayers.map(player => (
//     //         <li
//     //             key={player.id}
//     //             className="list-group-item d-flex justify-content-between align-items-center"
//     //         >
//     //             <span className={`todo-title me-2`}
//     //                   title={player.first_name + ' ' + player.last_name}>
//     //                   <img src={team.logo} alt={team.name + ' logo'} ></img>
//     //                   {team.location + ' '}
//     //                   {team.name}
//     //             </span>
//     //             <span>
//     //                 <Button className="btn btn-dark me-2">Player Page (coming soon)</Button>
//     //             </span>
//     //         </li>
//     //     ))
//     //
//     // };
//
//     render() {
//         return(
//             // <main className="context">
//                 <BrowserRouter>
//                     <Routes>
//                         <Route
//                             path="/"
//                             element=<Home />
//                         />
//                     </Routes>
//                 </BrowserRouter>
//                 {/*// <h1 className="muffin text-black text-center me-1" > Muffin Hockey </h1>*/}
//                 {/*// <div className="row">*/}
//                 {/*//     <div className="text-center col-me-6 col-sma-10 mc-auto p-0 ">*/}
//                 {/*//         <div className="card p-3 text-center">*/}
//                 {/*//             /!*<select className="select2-dropdown--below">*!/*/}
//                 {/*//             /!*    {this.renderTeamList()}*!/*/}
//                 {/*//             /!*</select>*!/*/}
//                 {/*//             <ul className="list-group">*/}
//                 {/*//                 {this.renderTeamList()}*/}
//                 {/*//             </ul>*/}
//                 {/*//             /!*<ul className="list-group list-group-flush">*!/*/}
//                 {/*//             /!*    /!*{this.renderItems()}*!/*!/*/}
//                 {/*//             /!*</ul>*!/*/}
//                 {/*//         </div>*/}
//                 {/*//     </div>*/}
//                 {/*// </div>*/}
//             // </main>
//         )
//     }
// }
//
// export default App;
//
//                 {/*// <h1 className="muffin text-black text-center me-1" > Muffin Hockey </h1>*/}
//                 {/*// <div className="row">*/}
//                 {/*//     <div className="text-center col-me-6 col-sma-10 mc-auto p-0 ">*/}
//                 {/*//         <div className="card p-3 text-center">*/}
//                 {/*//             /!*<select className="select2-dropdown--below">*!/*/}
//                 {/*//             /!*    {this.renderTeamList()}*!/*/}
//                 {/*//             /!*</select>*!/*/}
//                 {/*//             <ul className="list-group">*/}
//                 {/*//                 {this.renderTeamList()}*/}
//                 {/*//             </ul>*/}
//                 {/*//             /!*<ul className="list-group list-group-flush">*!/*/}
//                 {/*//             /!*    /!*{this.renderItems()}*!/*!/*/}
//                 {/*//             /!*</ul>*!/*/}
//                 {/*//         </div>*/}
//                 {/*//     </div>*/}
//                 {/*// </div>*/}
//             // </main>
//         )
//     }
// }
//
// export default App;


// export default function App() {
//     return (
//         <React.StrictMode>
//           <BrowserRouter>
//             <Routes>
//                 <Route index element={<Home/>}/>
//                 <Route path="/" element={<Home/>}/>
//                 <Route path="/teams/:teamAbb" element={<TeamDetailPage teamProps/>}/>
//             </Routes>
//           </BrowserRouter>
//         </React.StrictMode>
//     )
// }

import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/Home";
import TeamPlayersPage from "./pages/TeamPlayersPage";
import TeamHomePage from "./pages/TeamHomePage"
import PlayerPage from "./pages/PlayerPage";
import React from "react";
import { NavBar} from './components/NavBar'
import styles from './App.module.css'
import TeamGamesPage from "./pages/TeamGamesPage";
import GamePage from "./pages/GamePage";
import {MuffinFooter} from "./components/MuffinFooter";

// export default const App = () => {
export default function App() {
    return(
        <BrowserRouter>
            <NavBar />
            <div className={styles.contentContainer}>
                <Routes>
                    <Route path="/" exact element={<HomePage />}/>
                    <Route path="/teams" element={<HomePage />}/>
                    <Route path="/teams/:teamAbb" element={<TeamHomePage />}/>
                    <Route path="/teams/:teamAbb/roster" element={<TeamPlayersPage />}/>
                    <Route path="/teams/:teamAbb/games" element={<TeamGamesPage />}/>
                    <Route path="/players/id/:playerId" element={<PlayerPage />}/>
                    <Route path="/games/id/:gameId" element={<GamePage />}/>
                </Routes>
            </div>
            <MuffinFooter/>
        </BrowserRouter>
    )
};