import styles from "../styles/NavBar.module.css";
import {Link} from "react-router-dom";


const MuffinFooter = () => {
    return (
        <nav className={styles.navBar}>
            <Link to="/">
                {/*<h1 className={styles.siteHeading}>Muffin Hockey</h1>*/}
                <img className={styles.muffinLogo} src={`${process.env.PUBLIC_URL}/muffinman.png`} alt={'muffin hockey logo'}/>
            </Link>
            {/*<Link to="/players">*/}
            {/*     <button className={styles.randomButton}>Random Player</button>*/}
            {/*</Link>*/}
        </nav>
    );
}
export { MuffinFooter };