import React, {Component, useEffect, useState} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import TeamDetailPage from "./TeamPlayersPage";
import profileStyles from '../styles/PlayerProfile.module.css'
import styles from '../styles/HomePage.module.css'
import { useParams } from 'react-router-dom'

function getFlagSrc(nationality) {
    const flagMap = {
        USA: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a9/Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg/510px-Flag_of_the_United_States_%28DoS_ECA_Color_Standard%29.svg.png',
        CAN: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cf/Flag_of_Canada.svg/1600px-Flag_of_Canada.svg.png?20190318175205',
        SWE: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Flag_of_Sweden.svg/1600px-Flag_of_Sweden.svg.png?20240601033150',
        FIN: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/bc/Flag_of_Finland.svg/1600px-Flag_of_Finland.svg.png?20230220191416',
        CZE: 'https://upload.wikimedia.org/wikipedia/commons/thumb/c/cb/Flag_of_the_Czech_Republic.svg/1599px-Flag_of_the_Czech_Republic.svg.png?20110920204324',
        RUS: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Russia.svg/1599px-Flag_of_Russia.svg.png?20120812011549',
        DEN: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9c/Flag_of_Denmark.svg/1024px-Flag_of_Denmark.svg.png?20230220190747',
        SUI: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/f3/Flag_of_Switzerland.svg/1024px-Flag_of_Switzerland.svg.png?20191016012602',
        SVK: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e6/Flag_of_Slovakia.svg/1599px-Flag_of_Slovakia.svg.png?20221214030619',
        UZB: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Uzbekistan.svg/1600px-Flag_of_Uzbekistan.svg.png?20230421060058',
        BEL: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/85/Flag_of_Belarus.svg/1600px-Flag_of_Belarus.svg.png?20230207071054',
        AUS: 'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b9/Flag_of_Australia.svg/1600px-Flag_of_Australia.svg.png?20211007161007',
        AUT: 'https://upload.wikimedia.org/wikipedia/commons/thumb/4/41/Flag_of_Austria.svg/1599px-Flag_of_Austria.svg.png?20220412141234',
        GBR: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg/1600px-Flag_of_the_United_Kingdom_%283-5%29.svg.png?20230715230526',
        LAT: 'https://upload.wikimedia.org/wikipedia/commons/thumb/8/84/Flag_of_Latvia.svg/1600px-Flag_of_Latvia.svg.png?20240509004811'
    };

    return flagMap[nationality] || 'Flag not found';
}


function getPositionString(nhl_position) {
    const positionMap = {
        L: 'LW',
        R: 'RW',
        C: 'C',
        D: 'D',
        G: 'G'
    };

    return positionMap[nhl_position] || '?';
}


function getAgeString(birth_date) {
    const birthDate = new Date(birth_date);
    const today = new Date();

    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const dayDifference = today.getDate() - birthDate.getDate();

    // Adjust age if the current date is before the birth date this year
    if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
        age--;
    }

    return age;
}


function getHeightString(height_inches) {
    console.log(height_inches + ' inches')
    const feet = Math.floor(height_inches / 12);
    const inches = height_inches % 12;
    return `${feet}'${inches}"`;
}


function PlayerProfile({ player }) {

    useEffect(() => {
        function getColor(value) {
            const minValue = 0;
            const maxValue = 100;
            const midValue = 50; // Midpoint value for the gradient

            const minColor = '#ea4336'; // Red
            const midColor = '#eeeeee'; // Gray
            const maxColor = '#34a853'; // Green

            let color;
            if (value <= midValue) {
                // Interpolate between red and gray
                const ratio = (value - minValue) / (midValue - minValue);
                color = interpolateColor(minColor, midColor, ratio);
            } else {
                // Interpolate between gray and green
                const ratio = (value - midValue) / (maxValue - midValue);
                color = interpolateColor(midColor, maxColor, ratio);
            }
            return color;
        }
        function interpolateColor(color1, color2, ratio) {
            const [r1, g1, b1] = hexToRgb(color1);
            const [r2, g2, b2] = hexToRgb(color2);

            const r = Math.round(r1 + (r2 - r1) * ratio);
            const g = Math.round(g1 + (g2 - g1) * ratio);
            const b = Math.round(b1 + (b2 - b1) * ratio);

            return `rgb(${r}, ${g}, ${b})`;
        }
        function hexToRgb(hex) {
            let r = 0, g = 0, b = 0;
            if (hex.length === 7) {
                r = parseInt(hex.slice(1, 3), 16);
                g = parseInt(hex.slice(3, 5), 16);
                b = parseInt(hex.slice(5, 7), 16);
            }
            return [r, g, b];
        }
        // const overallCircle = document.getElementById('overall');
        // const value = parseInt(overallCircle.getAttribute('data-value'), 10);
        // const circle = overallCircle.querySelector(`.${profileStyles.overallCircle}`);
        // if (circle) {
        //     circle.style.borderColor = getColor(value);
        // }

        const cells = document.querySelectorAll(`.${profileStyles.analytic}`);
        cells.forEach(cell => {
            const value = parseInt(cell.getAttribute('data-value'), 10);
            const circle = cell.querySelector(`.${profileStyles.analyticCircle}`);
            if (circle) {
                circle.style.borderColor = getColor(value);
            }
        });

        const loadingContainers = document.getElementsByName('loadingBar');
        loadingContainers.forEach(loadingContainer => {
            const loadingBar = loadingContainer.querySelector(`.${profileStyles.loadingBar}`);
            const loadingText = loadingBar.querySelector(`.${profileStyles.loadingText}`);
            const loadingValue = parseInt(loadingContainer.getAttribute('data-value'), 10);

            // Ensure the value is between 0 and 100
            const percentage = Math.max(0, Math.min(100, loadingValue));

            // Set the width of the loading bar based on the data-value attribute
            loadingBar.style.width = `${percentage}%`;
            loadingBar.style.backgroundColor = getColor(loadingValue);
            // Set the text inside the loading bar
            loadingText.textContent = `${percentage}`;
        });

        const overallContainer = document.getElementById('overallBar')
        const overallBar = overallContainer.querySelector(`.${profileStyles.overallBar}`);
        const overallText = overallBar.querySelector(`.${profileStyles.overallText}`);
        const overallValue = parseInt(overallContainer.getAttribute('data-value'), 10);
        const percentage = Math.max(0, Math.min(100, overallValue));
        overallBar.style.width = `${percentage}%`;
        overallBar.style.backgroundColor = getColor(overallValue);
        // Set the text inside the loading bar
        overallText.textContent = `${percentage}`;

    }, [player]); // Empty dependency array ensures this runs only once after initial render

    return (
        <div>
            <div className={profileStyles.blur}>
                <div className={profileStyles.blurMessage}>
                    <h1>Player cards are under construction</h1>
                    <p>Please check back next week :)</p>
                    <img className={styles.muffinLogo} src={`${process.env.PUBLIC_URL}/muffinhockey.png`}
                         alt={'muffin hockey logo'}/>
                </div>
            </div>
            <div className={profileStyles.tableOutline}>
                <div>
                <div className={profileStyles.profileHeader}>
                        <img
                            className={profileStyles.mug}
                            src={`https://assets.nhle.com/mugs/nhl/20232024/${player.current_team}/${player.id}.png`}
                            alt={`${player.first_name + player.last_name}`}
                        />
                        <div className={profileStyles.profileInfo}>
                            <div className={profileStyles.nameBarContainer}>
                                <h1 className={profileStyles.nameBar}>
                                    {player.first_name} {player.last_name}
                                </h1>
                            </div>
                            <div className={profileStyles.bioBackground}>
                                <div className={profileStyles.bioContainer}>
                                    <div className={profileStyles.bioBox}>
                                        <div className={profileStyles.bioItem}>
                                            <img className={profileStyles.teamLogo}
                                                 src={`https://assets.nhle.com/logos/nhl/svg/${player.current_team}_light.svg`}
                                                 alt={`${player.current_team} logo`}
                                            />
                                        </div>
                                        <div className={profileStyles.bioItem}>{getPositionString(player.position)}</div>
                                        <div className={profileStyles.bioItem}>{getAgeString(player.birth_date)}</div>
                                        <div className={profileStyles.bioItem}>{getHeightString(player.height_inches)}</div>
                                        <div className={profileStyles.bioItem}>{player.weight_lbs + ' lbs'}</div>
                                        <div className={profileStyles.bioItem}>
                                            <img className={profileStyles.flag}
                                                 src={`${getFlagSrc(player.nationality)}`}
                                                 alt={`${player.nationality} flag`}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {/*<div className={profileStyles.bioContainer}>*/}
                                {/*    <div className={profileStyles.bioBox}>*/}
                                {/*        <div className={profileStyles.bioItem}>*/}
                                {/*            <img className={profileStyles.teamLogo}*/}
                                {/*                 src={`https://assets.nhle.com/logos/nhl/svg/${player.current_team}_light.svg`}*/}
                                {/*                 alt={`${player.current_team} logo`}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*        <div className={profileStyles.bioItem}>LW</div>*/}
                                {/*        <div className={profileStyles.bioItem}>$7.4M x 4</div>*/}
                                {/*        <div className={profileStyles.bioItem}>RFA 2026</div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                            <div className={profileStyles.overallContainer} data-value="12" id="overallBar">
                                <div className={profileStyles.overallBar}>
                                    <span className={profileStyles.overallText}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className={profileStyles.divider}/>
                    <div className={profileStyles.gridContainer}>
                        <div className={profileStyles.analyticCategory}>
                            <h1>Offense</h1>
                            <div className={profileStyles.loadingContainer} data-value="1" name="loadingBar">
                                <div className={profileStyles.loadingBar}>
                                    <span className={profileStyles.loadingText}></span>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>xGoals</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Goals</td>
                                    <td className={profileStyles.analytic} data-value="34">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>34</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Primary Assists</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Secondary Assists</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Shots</td>
                                    <td className={profileStyles.analytic} data-value="50">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>50</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={profileStyles.analyticCategory}>
                            <h1>Defense</h1>
                            <div className={profileStyles.loadingContainer} data-value="78" name="loadingBar">
                                <div className={profileStyles.loadingBar}>
                                    <span className={profileStyles.loadingText}></span>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>Turnovers</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Penalties</td>
                                    <td className={profileStyles.analytic} data-value="34">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>34</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Blocked Shots</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Faceoffs</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Hits</td>
                                    <td className={profileStyles.analytic} data-value="60">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>60</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={profileStyles.analyticCategory}>
                            <h1>Impact</h1>
                            <div className={profileStyles.loadingContainer} data-value="83" name="loadingBar">
                                <div className={profileStyles.loadingBar}>
                                    <span className={profileStyles.loadingText}></span>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>EV Offense</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>EV Defense</td>
                                    <td className={profileStyles.analytic} data-value="34">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>34</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>PP</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>PK</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Overall Impact</td>
                                    <td className={profileStyles.analytic} data-value="85">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>85</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={profileStyles.analyticCategory}>
                            <h1>Usage</h1>
                            <div className={profileStyles.loadingContainer} data-value="77" name="loadingBar">
                                <div className={profileStyles.loadingBar}>
                                    <span className={profileStyles.loadingText}></span>
                                </div>
                            </div>
                            <table>
                                <tbody>
                                <tr>
                                    <td>EV TOI</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>PP TOI</td>
                                    <td className={profileStyles.analytic} data-value="34">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>34</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>SH TOI</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Zone Starts</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>QOT</td>
                                    <td className={profileStyles.analytic} data-value="75">
                                        <div className={profileStyles.analyticCircle}>
                                            <span>75</span>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

// function PlayerPage() {
//     const params = useParams();
//     const playerId = params.playerId
//     const [player, setPlayer] = useState([])
//     const [team, setTeam] = useState([])
//     const [loading, setLoading] = useState(true);
//
//     useEffect(() => {
//         getData()
//             .catch(err => {
//                 console.log(err);
//             });
//     }, [])
//
//     const getData = async () => {
//         setLoading(true)
//         let apiUrl =
//             `http://localhost:8000/api/players/id/${playerId}`
//         const playerResp = await fetch(apiUrl)
//         const playerInfo = await playerResp.json()
//         setPlayer(playerInfo)
//         setLoading(false)
//     }
//
//     if (loading) return (
//         <div className="d-flex justify-content-center">
//             <div className="spinner-border" role="status">
//                 <span className="visually-hidden">Loading...</span>
//             </div>
//         </div>
//     );
//
//     return (
//         PlayerProfile(player)
//     )
//
// }
function PlayerPage() {
    const {playerId} = useParams(); // Destructure playerId from params
    const [player, setPlayer] = useState(null); // Initialize as null for no data
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getData() {
            setLoading(true);
            try {
                const response = await fetch(`https://muffinhockey.com/api/players/id/${playerId}`);
                const playerInfo = await response.json();
                console.log(playerInfo)
                setPlayer(playerInfo);
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        }

        getData();
    }, [playerId]); // Add playerId as a dependency

    if (loading) return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );

    return player ? <PlayerProfile player={player}/> : <div>Player not found</div>;
}


export default PlayerPage


