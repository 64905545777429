import React, {Component, useEffect, useState} from "react";
import {BrowserRouter, Link, Route, Routes} from "react-router-dom";
import TeamDetailPage from "./TeamPlayersPage";
// import styles from '../styles/TeamPlayersPage.module.css'
import styles from '../styles/TeamGamesPage.module.css'
import { useParams } from 'react-router-dom'
import profileStyles from "../styles/PlayerProfile.module.css";
import SeasonDropdown from "../components/SeasonDropdown";


const formatDate = (dateString) => {
    // const options = { year: 'numeric', month: 'long', day: 'numeric' };
    // return new Date(dateString).toLocaleDateString(undefined, options);
    const [year, month, day] = dateString.split('-').map(Number);
    const date = new Date(year, month - 1, day); // Create a date object in local time
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};


function RenderGameList({ games, teamAbb }) {
    useEffect(() => {
        const rows = document.getElementsByName('gameRow');
        rows.forEach(row => {
            const winner = row.getAttribute('winner');
            if (teamAbb === winner || teamAbb === "NHL") {
                row.style.backgroundColor = '#d9fcd2';
            } else {
                row.style.backgroundColor = '#f7e3e1';
            }
        });
    }, [games, teamAbb]);

    const renderGameScore = (game) => {
        let suffix = "";
        if (game.final_period === "OT") {
            suffix = " (OT)";
        } else if (game.final_period === "SO") {
            suffix = " (SO)";
        }
        return (
            <div className="mr-2">
                <strong>{game.winner_score}</strong> {' — ' + game.loser_score + suffix}
                {/*<strong>{game.winner + ' ' + game.winner_score}</strong> {' — ' + game.loser_score + ' ' + game.loser + suffix}*/}
                {/*{game.winner + ' ' + game.winner_score + ' — ' + game.loser_score + ' ' + game.loser + suffix}*/}
            </div>
        );
    };

    return games.map(game => (
        <Link key={game.id} className={styles.gameRows} to={`/games/id/${game.id}`}>
            {/*<li className="list-group-item list-group-item-action d-flex justify-content-between"*/}
            {/*    key={game.id}*/}
            {/*>*/}
            <li className={styles.gameRow} key={game.id} winner={game.winner} name="gameRow">
                <div className="mr-2">
                    {formatDate(game.date)}
                </div>
                <span className="me-10">
                   <img className={styles.teamLogo}
                        src={`https://assets.nhle.com/logos/nhl/svg/${game.winner}_light.svg`}
                        alt={`${game.winner} logo`}
                   />
                </span>
                {renderGameScore(game)}
                <span className="me-10">
                   <img className={styles.teamLogo}
                        src={`https://assets.nhle.com/logos/nhl/svg/${game.loser}_light.svg`}
                        alt={`${game.loser} logo`}
                   />
                </span>
            </li>
        </Link>
    ));
}

function GamesTable({ games, team, handleSeasonChange, selectedSeason  }) {
    const params = useParams();
    const teamAbb = params.teamAbb

    let teamTitle;
    if (teamAbb === "NHL") {
        teamTitle = "   All Games";
    } else {
        teamTitle = team.location + ' ' + team.name + ' Games';
    }

    return (
        <main className={styles.mainTable}>
            <h1 className={styles.teamTableHeading}>
                <img className={styles.teamLogo}
                         // src={`https://assets.nhle.com/logos/nhl/svg/${teamAbb}_light.svg`}
                        src={`${process.env.PUBLIC_URL}/team_logos/${teamAbb}.png`}
                         alt={`${teamAbb} logo`}
                />
                {teamTitle}
            </h1>
            {/* Season Dropdown */}
            <SeasonDropdown selectedSeason={selectedSeason} onSeasonChange={handleSeasonChange}/>
            <div className="row">
                <div className="text-center col-me-6 col-sma-10 mc-auto p-0 ">
                    <div className="card p-3 text-center">
                        <ul className="list-group">
                            <RenderGameList games={games} teamAbb={teamAbb} />
                        </ul>
                    </div>
                </div>
            </div>
        </main>
    );
}

function TeamGamesPage() {
    // const teamAbb = searchParams.get('teamAbb')
    const params = useParams();
    const teamAbb = params.teamAbb
    const [games, setGames] = useState([])
    const [team, setTeam] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedSeason, setSelectedSeason] = useState('20232024');  // Default season

    const handleSeasonChange = (season) => {
        setSelectedSeason(season);
    };


    useEffect(() => {
        getData()
            .catch(err => {
                console.log(err);
      });

    }, [])

    useEffect(() => {
      if (selectedSeason) {
        getData(selectedSeason).catch((err) => {
          console.log(err);
        });
      }
    }, [selectedSeason, teamAbb]); // Include teamAbb if it can change

    const getData = async () => {
        setLoading(true)
        let apiUrl =
        `https://muffinhockey.com/api/games/${teamAbb}/${selectedSeason}`
        const gameListResp = await fetch(apiUrl)
        const gameList = await gameListResp.json()
        setGames(gameList)

        if (teamAbb !== "NHL") {
            apiUrl = `https://muffinhockey.com/api/teams/${teamAbb}`
            const teamResp = await fetch(apiUrl)
            const teamData = await teamResp.json()
            // console.log(teamData)
            setTeam(teamData)
        }
        setLoading(false)
    }
    if(loading) return (
        <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );

    return <GamesTable games={games} team={team} handleSeasonChange={handleSeasonChange} selectedSeason={selectedSeason} />;

}

export default TeamGamesPage


