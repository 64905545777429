// import React, { useState } from 'react';
// import styles from "../styles/SeasonDropdown.module.css";
//
// const seasonChoices = [
//     { value: '20232024', text: '2023-2024' },
//     { value: '20222023', text: '2022-2023' },
// ];
//
// function SeasonDropdown({ selectedSeason, onSeasonChange }) {
//     const [selectedSeason, setSelectedSeason] = useState('20232024');  // Default to 2023-2024
//
//     const handleSeasonChange = (event) => {
//         const selectedValue = event.target.value;
//         setSelectedSeason(selectedValue);
//         onSeasonChange(selectedValue);  // Pass the selected season to the parent component
//     };
//
//     return (
//         <div className={styles.dropdownContainer}>
//             <label htmlFor="season-select" className={styles.dropdownLabel}>Season</label>
//             <select
//                 id="season-select"
//                 className={styles.dropdown}
//                 value={selectedSeason}  // Controlled input
//                 onChange={handleSeasonChange}
//             >
//                 <option value="">Select a Season</option>
//                 {seasonChoices.map((season) => (
//                     <option key={season.value} value={season.value}>
//                         {season.text}
//                     </option>
//                 ))}
//             </select>
//         </div>
//     );
// }
//
// export default SeasonDropdown;
import React from 'react';
import PropTypes from 'prop-types';
import styles from '../styles/SeasonDropdown.module.css'; // Adjust the path as necessary

function SeasonDropdown({ selectedSeason, onSeasonChange }) {
    const handleSeasonChange = (event) => {
        const selectedValue = event.target.value;
        onSeasonChange(selectedValue);  // Update parent state
    };

    const seasonChoices = [
        { value: '20232024', text: '2023-2024' },
        { value: '20222023', text: '2022-2023' },
        // { value: '20212022', text: '2021-2022' },
        // { value: '20202021', text: '2020-2021' },
        // Add more seasons as needed
    ];

    return (
        <div className={styles.dropdownContainer}>
            <label htmlFor="season-select" className={styles.dropdownLabel}>Season</label>
            <select
                id="season-select"
                className={styles.dropdown}
                value={selectedSeason}
                onChange={handleSeasonChange}
            >
                <option value="">Select a Season</option>
                {seasonChoices.map((season) => (
                    <option key={season.value} value={season.value}>
                        {season.text}
                    </option>
                ))}
            </select>
        </div>
    );
}

SeasonDropdown.propTypes = {
    selectedSeason: PropTypes.string.isRequired,
    onSeasonChange: PropTypes.func.isRequired,
};

export default SeasonDropdown;